import { useMemo, useRef } from 'react';
import { useAdEventHandler, useTracker } from '@archipro-website/tracker';
import { Button } from '@rocco/components/button';
import { RoccoLink } from '@rocco/components/link/RoccoLink';
import { ReactComponent as ArrowRight } from '@rocco/icons/svg/arrow/arrow-right.svg';
import { cn } from '@rocco/utils/cn';
import type { AdvertCreative } from '@modules/adverts/type';
import { useHomePageBannerEventHandler } from '@modules/home/util/home-banner-tracker-helpers';
import { createLinkedComponentParams } from '~/utils/urlHelper';

export interface HeaderOverlayProps {
    className?: string;
    title: string;
    ctaLink: string;
    isExternal?: boolean;
    ctaLabel: string;
    id: number;
    category: string;
    advertCreative?: AdvertCreative | null;
    /** Start from 1. */
    position?: number;
}

/**
 * Text and actions for the header banner carousel.
 */
export default function HeaderOverlay({
    className,
    title,
    ctaLink,
    isExternal,
    ctaLabel,
    id,
    category,
    advertCreative = null,
    position = 1,
}: HeaderOverlayProps) {
    const bannerRef = useRef<HTMLDivElement>(null);
    const tracker = useTracker();
    const { onClick: adClick } = useAdEventHandler(advertCreative, bannerRef);
    const { onClick: bannerClick } = useHomePageBannerEventHandler(
        !advertCreative
            ? {
                  type: 'FullWidth',
                  itemID: id,
                  position,
              }
            : null,
        bannerRef
    );
    // Use different tracking events depending on if this is an ad or not.
    const handleClick = useMemo(() => {
        if (advertCreative) {
            return adClick;
        }
        return bannerClick;
    }, [advertCreative, adClick, bannerClick]);

    return (
        <div
            ref={bannerRef}
            className={cn('flex cursor-default flex-col gap-y-4', className)}
        >
            <div className="text-[0.75rem] font-medium uppercase tracking-[0.14em] text-black md:font-['GT_America',sans-serif] md:text-base md:font-normal md:tracking-[0.19em] md:text-gray-700">
                {category}
            </div>
            <div className="max-w-75 font-['GT_America',sans-serif] text-7.5 leading-none tracking-[-0.01em] md:max-w-none md:text-11">
                {title}
            </div>
            <Button
                asChild
                className="mt-7 w-fit bg-gray-800 px-8.5 py-4 font-['GT_America',sans-serif] text-sm uppercase hover:bg-black md:mt-3 md:px-6 md:py-3 md:font-sans md:font-normal"
                color="dark"
                shape="square"
            >
                <RoccoLink
                    to={ctaLink}
                    prefetch="intent"
                    isNonRemixRoute={isExternal}
                    {...createLinkedComponentParams(
                        tracker,
                        'Ad_CTAClick',
                        ctaLink,
                        {
                            libraryItemID: advertCreative?.professional.id,
                            professionalID: advertCreative?.professional.id,
                        },
                        null,
                        handleClick
                    )}
                >
                    {ctaLabel}
                    <ArrowRight className="ml-2 size-5" />
                </RoccoLink>
            </Button>
        </div>
    );
}
