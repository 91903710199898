import { useEffect, useState } from 'react';
import { cn } from '@rocco/utils/cn';

interface HeaderProgressProps {
    /** Total number of slides. */
    total: number;
    /** Current slide index. */
    current: number;
    /** Time in ms it takes to go to the next slide. */
    delay?: number;
}

/**
 * The progress bar for the header banner.
 */
const HeaderProgress = ({
    total,
    current,
    delay = 4000,
}: HeaderProgressProps) => {
    // Start animation after render to animate the first bar.
    const [isAnimating, setIsAnimating] = useState(false);
    useEffect(() => {
        setIsAnimating(true);
    }, []);

    return (
        <div className="flex items-center gap-x-1.5 md:gap-x-2.5">
            {[...Array(total)].map((_, i) => (
                <div
                    key={i}
                    className={cn(
                        'h-1.5 w-10 bg-[#D9D9D9] md:w-10.5',
                        i < current && 'bg-black'
                    )}
                >
                    <div
                        className={cn(
                            'h-full bg-black transition-[width] ease-linear',
                            i === current && isAnimating
                                ? 'w-full'
                                : 'invisible w-0'
                        )}
                        style={{
                            transitionDuration:
                                i === current ? `${delay}ms` : '0ms',
                        }}
                    ></div>
                </div>
            ))}
        </div>
    );
};

export default HeaderProgress;
