import { useRef } from 'react';
import { useAdEventHandler } from '@archipro-website/tracker';
import { Image, type ImageProps } from '@rocco/components/image';
import { RoccoLink } from '@rocco/components/link/RoccoLink';
import type { AdvertCreative } from '~/modules/adverts/type';

interface HeaderImageProps {
    image: ImageProps;
    ctaLink?: string;
    isExternal?: boolean;
    advertCreative?: AdvertCreative | null;
}

const HeaderImage = ({
    image,
    ctaLink,
    isExternal,
    advertCreative = null,
}: HeaderImageProps) => {
    const imageRef = useRef(null);
    // We have fired the serve and impression events in HeaderOverlay, so we disable them here.
    const { onClick } = useAdEventHandler(advertCreative, imageRef, {
        disableServeEvent: true,
        disableImpressionEvent: true,
    });

    return (
        <div
            ref={imageRef}
            className="h-full w-full overflow-hidden rounded-2.5 bg-gray-50"
        >
            {ctaLink ? (
                <RoccoLink
                    to={ctaLink}
                    prefetch="intent"
                    isNonRemixRoute={isExternal}
                    onClick={() => {
                        onClick('Ad_ImageClick');
                    }}
                >
                    <Image multiplied size="full" fadeIn={false} {...image} />
                </RoccoLink>
            ) : (
                <Image multiplied size="full" fadeIn={false} {...image} />
            )}
        </div>
    );
};

export default HeaderImage;
