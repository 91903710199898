import { useState, useEffect, useRef } from 'react';
import { useInViewport } from 'ahooks';
import { useAtomValue } from 'jotai';
import type SwiperCore from 'swiper';

import { Carousel, pxToRem } from '@archipro-design/aria';
import { ReactComponent as ArrowLeftIcon } from '@rocco/icons/svg/arrow/arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from '@rocco/icons/svg/arrow/arrow-right.svg';
import { uiContextAtomDisplayMode } from '@rocco/states/uiContextAtomDisplayMode';
import { cn } from '@rocco/utils/cn';
import { getImagesSizes } from '~/utils/image-sizes-utils';
import HeaderImage from './HeaderImage';
import HeaderOverlay, { type HeaderOverlayProps } from './HeaderOverlay';
import HeaderProgress from './HeaderProgress';

interface HeaderBannerProps {
    bannerTiles: (HeaderOverlayProps & { imageSrc: string })[];
    delay: number;
}

/**
 * A carousel of images and text for the header banner on the home page.
 */
const HeaderBanner = ({ bannerTiles, delay }: HeaderBannerProps) => {
    const { isMobile } = useAtomValue(uiContextAtomDisplayMode);
    const bannerHeight = isMobile ? 488 : 882;
    const bannerWidth = isMobile ? 414 : 749;
    const carouselRef = useRef(null);
    const [currentTile, setCurrentTile] = useState<number>(0);
    const [headerSwiper, setHeaderSwiper] = useState<SwiperCore | null>(null);
    const [inViewport] = useInViewport(carouselRef, { threshold: 0.75 });

    useEffect(() => {
        headerSwiper?.on('slideChangeTransitionEnd', () => {
            setCurrentTile(headerSwiper.realIndex);
        });
    }, [headerSwiper]);

    return (
        <div className="relative leading-none">
            <Carousel
                ref={carouselRef}
                getSwiperApi={(swiper) => {
                    setHeaderSwiper(swiper);
                }}
                aria-roledescription="carousel"
                items={bannerTiles.map((banner, idx) => {
                    return (
                        <HeaderImage
                            key={idx}
                            image={{
                                loading: idx === 0 ? 'eager' : 'lazy',
                                width: bannerWidth,
                                height: bannerHeight,
                                src: banner.imageSrc,
                                alt: banner.title,
                                sizes: getImagesSizes({
                                    desktop: bannerWidth,
                                    mobile: bannerWidth,
                                }),
                                fit: 'cover',
                            }}
                            ctaLink={banner.ctaLink}
                            isExternal={banner.isExternal}
                            advertCreative={banner.advertCreative}
                        />
                    );
                })}
                autoplayEnabled={inViewport}
                autoplay={{
                    disableOnInteraction: false,
                    delay: delay,
                }}
                navigation
                loop
                effect="fade"
                fadeEffect={{
                    crossFade: true,
                }}
                hidePaddles
                variables={{
                    overlayPadding: pxToRem(0),
                    overlayInitialOpacity: 1,
                    containerWidth: pxToRem(bannerWidth),
                    containerHeight: pxToRem(bannerHeight),
                }}
            />
            <div className="px-4.5 pt-5.5 md:absolute md:bottom-67 md:right-10 md:w-120 md:py-8 md:pl-11 md:pr-0">
                {bannerTiles.map((banner, idx) => (
                    <HeaderOverlay
                        key={idx}
                        className={cn(idx !== currentTile && 'hidden')}
                        title={banner.title}
                        ctaLink={banner.ctaLink}
                        isExternal={banner.isExternal}
                        ctaLabel={banner.ctaLabel}
                        id={banner.id}
                        category={banner.category}
                        advertCreative={banner.advertCreative}
                        position={idx + 1}
                    />
                ))}
            </div>
            <div className="mx-5 flex items-center justify-end gap-x-2.5 border-b border-gray-200 py-5 md:absolute md:bottom-9 md:right-15 md:mx-0 md:border-b-0">
                {!isMobile && (
                    <button
                        className="flex size-6 items-center justify-center p-0.5"
                        aria-label="Previous"
                        onClick={() => {
                            headerSwiper?.slidePrev();
                        }}
                    >
                        <ArrowLeftIcon />
                    </button>
                )}
                <HeaderProgress
                    total={bannerTiles.length}
                    current={currentTile}
                    delay={delay}
                />
                {!isMobile && (
                    <button
                        className="flex size-6 items-center justify-center p-0.5"
                        aria-label="Next"
                        onClick={() => {
                            headerSwiper?.slideNext();
                        }}
                    >
                        <ArrowRightIcon />
                    </button>
                )}
            </div>
        </div>
    );
};

export default HeaderBanner;
